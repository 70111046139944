import React, {useState, useRef, useEffect} from "react"
import "../index.scss"

import Layout from "src/components/layout"
import Head from "src/components/Head"

import SportObjectsSearch from "src/components/clients/SportObjectsSearch/SportObjectsSearch"
import Start from "src/components/Start/Start"
import Top from "src/components/Top/Top"
import Top2 from "src/components/Top2/Top2"
import HowItWorks from "src/components/companies/HowItWorks/HowItWorks"
import Benefits from "src/components/companies/Benefits/Benefits"

import COUNTRY from "../../constants"
import { graphql, useStaticQuery } from "gatsby"
import { useTranslation } from "react-i18next"
import BRANDS from "../../brands"

const ClientsMain = ({ location }) => {
  const data = useStaticQuery(graphql`
    query mainPageClientsUa {
      site {
        siteMetadata {
          senderPath
          countries {
            ua {
              hotLine {
                tel
                title
              }
            }
          }
        }
      }
    }
  `)

  const { t,i18n } = useTranslation()
  useEffect(() => {
    i18n.changeLanguage(COUNTRY.UA)
  }, [i18n])

  const [email, setEmail] = useState("")
  const [emailDisabled, setEmailDisabled] = useState(false)
  const [form, setForm] = useState({
    company: "",
    country: COUNTRY.UA
  })
  const sendForm = (form) => {
    setForm(form)
    return fetch(data.site.siteMetadata.senderPath, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(form),
    })
      .then((response) => {
        alert(t("sendFormAlert"))
      })
      .catch((e) => {
        console.error(e)
      })
  }
  const transferEmail = (email) => {
    setForm(Object.assign(form, { email }))
    setEmailDisabled(true)
  }

  const startRef = useRef()
  return (
    <div className="main-page">
      <Head
        lang="ua"
        title={t('head.index.title', { brand: BRANDS.ALLSPORTS.brand })}
        description={t('head.description', { brand: BRANDS.ALLSPORTS.brand })}
        slug="/"
      />
      <Layout
        hotLine={data.site.siteMetadata.countries.ua.hotLine}
        location={location}
        country="ua"
      >
        <Top2
          type="clients"
          startRef={startRef}
          email={email}
          setEmail={setEmail}
          transferEmail={transferEmail}
          platinum_amount={''}
          platinum_activities_amount={''}
          country="ua"
        />
        <SportObjectsSearch country={COUNTRY.UA} />
        <Top
          type="companies"
          startRef={startRef}
          email={email}
          setEmail={setEmail}
          transferEmail={transferEmail}
          platinum_amount={''}
          country="ua"
        />
        <HowItWorks />
        <Benefits />
        <Start
          type="companies"
          startRef={startRef}
          email={email}
          emailDisabled={emailDisabled}
          setEmail={setEmail}
          setForm={sendForm}
        />
      </Layout>
    </div>
  )
}

export default ClientsMain
